<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
        >
          <b-form-group
            label="Başlık"
            label-for="title"
          >
            <validation-provider
              #default="{ errors }"
              name="Başlık"
              rules="required"
            >
              <b-form-input
                id="title"
                v-model="itemData.title"
                placeholder="Başlık"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="İçerik"
            label-for="content"
          >
            <quill-editor
              v-model="itemData.content"
              :options="editorOption"
              class="bg-white text-black-50"
            >
              <div
                id="content_toolbar"
                slot="toolbar"
              >
                <editor-toolbar />
              </div>
            </quill-editor>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Durum"
            label-for="status"
          >
            <v-select
              id="status"
              v-model="itemData.status"
              :options="statuses"
              placeholder="Durum"
              label="title"
              :reduce="item => item.id"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <template v-if="!itemData.attachment">
            <b-form-group
              label-for="file-upload"
              label="Dosya Eki"
            >
              <b-form-file
                id="file-upload"
                v-model="file_upload"
                placeholder="Bir görsel seçin veya buraya bırakın..."
                drop-placeholder="Görseli buraya bırakın..."
                browse-text="Dosya Seçin"
              />
            </b-form-group>
            <template v-if="file_upload">
              <b-button
                variant="success"
                @click="uploadFile(file_upload,'assembly')"
              >
                <FeatherIcon icon="UploadIcon" />
                Dosya Yükle
              </b-button>
              <b-button
                class="ml-1"
                variant="danger"
                @click="cancelUpload"
              >
                <FeatherIcon icon="XIcon" />
                Vazgeç
              </b-button>
            </template>
          </template>
          <template v-else>
            <div class="d-flex justify-content-between">
              <b-button
                variant="success"
                :href="baseURL + '/media/announcements/' + itemData.attachment"
                target="_blank"
              >
                <FeatherIcon icon="DownloadIcon" />
                Dosya Eki Görüntüle
              </b-button>
              <b-button
                variant="danger"
                class="ml-1"
                @click="removeFile"
              >
                <FeatherIcon icon="TrashIcon" />
                Dosya Sil
              </b-button>
            </div>
          </template>
        </b-col>
      </b-row>
    </b-card>
    <b-button
      variant="primary"
      :disabled="file_upload"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BCard, BCol, BFormGroup, BFormInput, BFormFile, BRow, BButton,
} from 'bootstrap-vue'
import EditorToolbar from '@/layouts/components/common/EditorToolbar.vue'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'ItemForm',
  components: {
    ValidationProvider,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BButton,
    quillEditor,
    EditorToolbar,
    vSelect,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      baseURL: this.$store.state.app.baseURL,
      editorOption: {
        modules: {
          toolbar: '#content_toolbar',
        },
        theme: 'snow',
      },
      file_upload: null,
      statuses: [
        {
          id: '1',
          title: 'Yayında',
        },
        {
          id: '2',
          title: 'Kapalı',
        },
      ],
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['announcements/dataItem']
    },
  },
  methods: {
    cancelUpload() {
      this.file_upload = null
    },
    uploadFile(fileData) {
      this.$store.dispatch('docsUpload/uploadFile', {
        folder: 'announcements',
        upload_file: fileData,
      })
        .then(response => {
          if (response.filename) {
            this.itemData.attachment = response.filename
            this.file_upload = null
          }
        })
    },
    removeFile() {
      this.itemData.attachment = null
    },
  },
}
</script>
